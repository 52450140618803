<template>
    <div>
        <div class="tp">近期历史记录(可自动播放)</div>
        <div>
            <div class="li" v-for="(item,index) in historylist" :key="index">
                <!-- :style="{ color: dynamicColorFun(item.play_song_info.song_colors) }" -->
                <div :class="zindex==index ? zindex==0?'':'bfs' :''" >歌名: {{item.play_song_info.song_name}}</div>
            </div>
        </div>

        <div class="tps">音乐品味</div>
        <div class="list_all">
            <div>最新喜欢</div>
            <div>{{like&&like.like&&like.like.play_song_info&&like.like.play_song_info.song_name}}</div>
            <div>累计听歌</div>
            <div>{{like&&like.play_num}}首</div>
        </div>
    </div>
</template>


<script>

import {bjolor,dynamicColor} from '../../../components/js/index'
export default {
    name: 'index',
    props:["historylist","zindex","colors" ,"like"],
    // props: {
    //     historyList: {
    //         type: Object,
    //         default: () => {
    //             return {
    //                 url: "",
    //                 color:"",
    //             }
    //         }
    //     },
    // },
    data() {
        return {
     
        }
    },
    components: {

    },
    all() {
      let Obj = this.historyList
      if (!Obj.url) {
        Obj.url = this.url
      }
 
      return Obj
    },
    mounted() {
      
   

    },
    methods: {
        dynamicColorFun(v){
            return dynamicColor(v)
        }
    },
}
</script>
<style>
.li{
    border-radius: 4px;
    width: 190px;
    height: 30px;
    background: rgb(255 255 255 / 17%);
    margin-top: 5px;
    border: 1px solid rgba(255,255,255,.5);
    line-height: 30px;
    padding-left: 9px;
    box-sizing: border-box;
    color: #111;
    overflow: hidden;
}
.tp{
    color: #222;
    margin-bottom:8px;
    margin-top: 6px;
}
.bfs{
    color: rgb(220, 220, 248);
}
.tps{
    color: #222;
    margin-bottom: 4px;
    margin-top: 28px;
}
.list_all{
    border-radius: 4px;
    width: 190px;
    min-height: 200px;
    background: rgb(255 255 255 / 17%);
    margin-top: 5px;
    border: 1px solid rgba(255,255,255,.5);
    line-height: 30px;
    padding-left: 9px;
    box-sizing: border-box;
    color: #111;
}


</style>
