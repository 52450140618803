<template>
  <div class="box">
    <left :left="left">
      <historylist :historylist="historyList" :zindex="zindex" :like="like"></historylist>
    </left>
    <topAudio :topAudio="topAudio">
      <!-- 插槽组件 -->
      <wave :datalist="datalist" @duration="duration" @currentTime="currentTime" @stop="stop"  ref="btn"></wave> 
    </topAudio>
    <wavesurfer :wavesurferObj="wavesurferObj" ref="wavesurfer" ></wavesurfer>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import left from '../../components/common/index/left.vue'
import topAudio from './components/topAudio.vue'
import wave from './components/wave.vue'
import wavesurfer from './components/wavesurfer.vue'
import api from '../../api/play'
import loading from '../../components/common/loading.vue'
import tips from '../../components/common/tips.vue'
import historylist from './components/leftHistory.vue'
import {bjolor} from '../../components/js/index'
export default {
  name: 'musicList',
  inject: ['reload'],
  data() {
    return {
      left: {
        isbg: "#00ffcd"
      },
      dataLife: "",
      datalist: {
        url: '',
        color: "",
        clientWidths: '100%',
        clientHeights: 250,
      },
      topAudio: {
        isTab: false,
        duration: "",
        currentTime: "",
        isBtnTab: this.isBtnTab,
        playList: {}
      },
      wavesurferObj: {
        color: "",
        url: '',
        widths:0,
        currentTime:0,
      },
      loading: {
        isShow: false
      },
      tips: {
        isShow: false
      },

      historyList:{},
      like:"",
      zindex:0,
    }
  },


  components: {
    left,
    topAudio,
    wave,
    wavesurfer,
    loading,
    tips,
    historylist
  },
  watch: {
    "topAudio.isTab"(v, i) {
      this.loading.isShow = false
    }
  },

  created() {

  },
  mounted() {
    var de = atob(this.$route.query.id); 
    var songsId = de
    this.songsFun(songsId)
    this.playLisFun()
  },
  beforeDestroy() {
    this.$refs.btn.stops()
    document.title = '海豚 dj'
  },
  methods: {
    // 历史
    playLisFun() {
      // var obj ={
      //   "pageNum": 2, 
      //   "pageSize": 100 
      // }
      // this.loading.isShow = true
      api.playList().then((res) => {
        // console.log(res.data.result, 6666);
        this.historyList=res.data.result
        // this.loading.isShow = false
      })

      api.withSongInfo().then((res) => {
        // console.log(res.data.result, 6666);
        this.like=res.data.result
        // this.loading.isShow = false
      })
    },

    songsFun(songsId) {
      // this.loading.isShow = true
      api.songs(songsId).then((res) => {
        if (res.data.code == 200) {
          // this.loading.isShow = false
          // console.log(res.data, 9999)
          this.datalist.url = 'http://82.157.140.72/test' + res.data.result.song_source +
            '?meAuth=' + sessionStorage.getItem("vkey") +
            '&vt=' + new Date().getTime() +
            '&vsid=' + ( atob(this.$route.query.id)) +
            '&uid=' + sessionStorage.getItem("uid")

          this.wavesurferObj.url = 'http://82.157.140.72/test' + res.data.result.song_source +
            '?meAuth=' + sessionStorage.getItem("vkey") +
            '&vt=' + new Date().getTime() +
            '&vsid=' + ( atob(this.$route.query.id))+
            '&uid=' + sessionStorage.getItem("uid")

          this.datalist.color = res.data.result.song_colors
          this.left.isbg =bjolor(res.data.result.song_colors)
          this.wavesurferObj.color = res.data.result.song_colors
          this.topAudio.playList = res.data.result
          document.title = this.topAudio.playList.song_author
          this.dataLife = res.data.result
        }
      })
    },
    // 点击播放 暂停
    isBtnTab() {
      this.playLisFun()
      // this.topAudio.isTab = !this.topAudio.isTab
      // if (this.topAudio.isTab) {
      //   await this.$refs.btn.play().then((res)=>{
      //     console.log(res,22222);
      //   })
      // } else {
      //   await this.$refs.btn.stops()
      // }

      this.$refs.btn.play().then((res) => {
        this.loading.isShow = true
        if (this.topAudio.duration > 0.1) {
          this.topAudio.isTab = true
          if (this.topAudio.isTab == true) {
            this.topAudio.isTab = false
            this.$refs.btn.stops()
          }
        }
      })
    },
    // 获取 时时时间 秒
    duration(val, m) {
      if (m > 0.1) {
        this.topAudio.isTab = true
      }
      this.topAudio.duration = m
      this.wavesurferObj.widths=m
    },
    // 获取 时间 总时长
    currentTime(val) {
      this.topAudio.currentTime = val
      this.wavesurferObj.currentTime=val
    },

    // 结束回调
    stop(val) {
      this.$refs.btn.loads()
      this.topAudio.isTab = false
      this.$refs.btn.stops()
     
      if(this.historyList.length<=1){
        console.log("1首不用循环");
      }else {
        this.zindex++
        this.playList()
      }
    },
    // 循环播放
    playList(){
      var arr =[]
      this.historyList.forEach((item,index) => {
          arr.push(item.play_song_id)
      });
      if(this.zindex == Number(arr.length)){
        this.$refs.btn.stops()
        this.loading.isShow = false
        this.zindex = this.zindex
        return alert("最后一首播完了返回")
      }else {
        this.isBtnTab()
        this.songsFun(arr[this.zindex])
      }

    }
    
  },
}
</script>

<style scoped>
.box {
  margin-left: 213px;
  margin-top: 60px;
}
</style>
