<template>
    <div>
        <div class="waveform_all" :style="{ background: bjolorFun(wavesurferObj.color) }">
            <!-- 点击拉拽 -->
            <div class="jd" @mousedown="down" @mousemove="move" @mouseup="end"
                :style="{ width: (wavesurferObj.widths / wavesurferObj.currentTime) * 100 + '%' }"></div>
            <!-- 音频轨道 -->
            <div id="waveform" @mousedown="down" @mousemove="move" @mouseup="end" ref="waveform"></div>
        </div>
    </div>
</template>


<script>
import { oneolor } from '../../../components/js/index'
import WaveSurfer from 'wavesurfer.js'

export default {
    name: 'index',
    props: {
        wavesurferObj: {
            type: Object,
            default: () => {
                return {
                    url: "",
                    color: "",
                    widths: "",
                    currentTime: "",
                }
            }
        },
    },
    data() {
        return {
            color: "",
            url: "",
            widths: "",
            currentTime: "",
        }
    },
    components: {

    },
    all() {
        let Obj = this.wavesurferObj
        if (!Obj.url) {
            Obj.url = this.url
        }
        if (!Obj.color) {
            Obj.color = this.color
        }
        if (!Obj.widths) {
            Obj.widths = this.widths
        }
        if (!Obj.currentTime) {
            Obj.currentTime = this.currentTime
        }
        return Obj
    },
    mounted() {
        var that = this
        setTimeout(() => {
            this.$nextTick(() => {
                this.wavesurfer = WaveSurfer.create({
                    container: this.$refs.waveform,
                    backend: "MediaElement",
                    barWidth: 0, // 如果设置，波纹的样式将变成类似柱状图的形状
                    cursorColor: "rgba(0,0,0,0)", // 竖杠颜色
                    waveColor: this.wavesurferObj.color, // 背景颜色
                    progressColor: "rgba(0,0,0,0)", // 动画颜色
                    height: 40,
                    audioRate: "1", // 音频的播放速度，数值越小越慢
                    cursorWidth: 1,
                    mediaControls: false, // 音频播放时间轴
                })
                setTimeout(() => {
                    this.wavesurfer.load(this.wavesurferObj.url)
                    // this.wavesurfer.load(require('/src/assets/11.mp3'))
                }, 1000)
            })
        }, 2500)


    },
    methods: {
        // 颜色
        bjolorFun(v) {
            return oneolor(v)
        },
        // btnWavesurfer 播放  现在不用这个
        btnWavesurfer() {
            // this.wavesurfer.playPause()
            //"播放/暂停"按钮的单击触发事件，暂停的话单击则播放，正在播放的话单击则暂停播放
            // this.wavesurfer.playPause.bind(this.wavesurfer)();
        },

        // 拉拽 
        down(e) {
            var that =this
            var alljd = document.getElementsByClassName('jd')[0]
            var alloffsetWidth = document.getElementsByClassName('waveform_all')[0].offsetWidth
            var a = that.$parent.$refs.btn.$data.audio.duration // 获取 音频 总长度 秒
            var b = e.layerX / (alloffsetWidth - 4) // 点击拉拽 占比 总div 宽度的%比
            console.log(a, "a", b, "b");
            var c = (that.wavesurferObj.widths / that.wavesurferObj.currentTime) * 100 // 占比10 不能点击进度
            if (a && (c > 3) && (that.$parent.$data.topAudio.isTab == true)) {
                (that.$parent.$refs.btn.$data.audio.currentTime) = a * b
                // alljd.style.width = e.layerX + "px";
            }
        },
        move(e) {

        },
        end(e) {
            // var alljd = document.getElementsByClassName('jd')[0]
            // var alloffsetWidth = document.getElementsByClassName('waveform_all')[0].offsetWidth
            // var a = this.$parent.$refs.btn.$data.audio.duration // 获取 音频 总长度 秒
            // var b = e.layerX / (alloffsetWidth - 4) // 点击拉拽 占比 总div 宽度的%比
            // var c = (this.wavesurferObj.widths / this.wavesurferObj.currentTime) * 100 // 占比10 不能点击进度
            // if (a && (c > 3) && (this.$parent.$data.topAudio.isTab == true)) {
            //     // alljd.style.width = e.layerX + "px";
            //     this.$parent.$refs.btn.$data.audio.currentTime = a * b
            // }
        }
    },
}
</script>
<style>
.waveform_all {
    width: calc(100% - 235px);
    position: fixed;
    bottom: 100px;
    background: #eee4e4;
    border-radius: 4px;
    padding: 2px;
    box-sizing: border-box;
    margin-left: 10px;
    overflow: hidden;
}

#waveform {
    width: 100%;
    height: 100%;
}

.jd {
    width: 0px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(86 50 50 / 60%);
    z-index: 3;
    height: 43px;
    cursor: pointer;
}
</style>
