<template>
    <div class="play">
        <div class="play_left">
            <div class="play_top">
                <div class="play_img">
                    <img class="cl_dh" :class="all.isTab ? 'dh' : ''" src="../../../assets/play_music.png" alt="">
                    <div class="play_start" :class="all.isTab ? 'isplay' : 'isstop'">
                        <img class="play_start_img" src="../../../assets/plays.png" alt="">
                    </div>
                    <div class="play_end" :class="all.isTab ? 'bf' : 'no'" @click="all.isBtnTab()">
                        <imgIng v-if="all.playList && all.playList.song_cover" class="play_end_image"
                            :src="'http://82.157.140.72/test' + (all.playList && all.playList.song_cover)" alt="">
                        </imgIng>
                    </div>
                </div>
                <div class="play_lan">
                    <div class="slot">
                        <slot></slot>
                    </div>
                    <div class="pod">
                        <div class="music_album_title">{{ all.playList.song_name }}</div>
                        <div class="music_album_span">
                            <!-- {{ all.currentTime }} -->
                            <div>时间：{{ all.duration }} s </div>
                        </div>
                        <div class="music_album_sc"  @click="btnCollection()" :class="favStatus ? 'bfs' : ''"
                           >
                            <span v-if="favStatus">已收藏</span>
                            <span v-else>收藏</span>
                        </div>
                        <div class="music_album_gz" @click="follow()">关注</div>
                        <div class="music_album_dz">播放量 {{ all.playList.song_play_num }}</div>
                    </div>
                </div>

            </div>
        </div>


    </div>
</template>

<script>

import imgIng from '../../../components/common/imgIng.vue'
export default {
    name: '',
    props: {
        topAudio: {
            type: Object,
            default: () => {
                return {
                    isHide: "", // 
                    isTab: false, // 点击切换
                    isBtnTab: () => { },
                    playList: {},
                    duration: "", // 歌曲长度
                    currentTime: "",// 歌曲更新时间
                    songPlayNum: "",
                }
            }
        },
    },
    data() {
        return {
            left: {
                isbg: "red"
            },
            playList: {},
            favStatus: false, //是否收藏
            duration: "", // 歌曲长度
            currentTime: "",// 歌曲更新时间
            songPlayNum: "",
            isBtnTab: () => { },
            isTab: false, // 点击切换
        }
    },


    components: {
        imgIng
    },
    computed: {
        all() {
            let Obj = this.topAudio
            if (!Obj.isTab) {
                Obj.isTab = this.isTab
            }
            if (!Obj.songPlayNum) {
                Obj.currentTime = this.currentTime
            }
            if (!Obj.songPlayNum) {
                Obj.songPlayNum = this.songPlayNum
            }
            if (!Obj.duration) {
                Obj.duration = this.duration
            }
            if (!Obj.playList) {
                Obj.playList = this.playList
            }

            if (!Obj.isBtnTab) {
                Obj.isBtnTab = this.isBtnTab
            }

            return Obj
        }
    },


    created() {

    },
    mounted() {



    },
    methods: {

        btnCollection(){
            this.favStatus =!this.favStatus
        },
        follow(){
            alert("开发中")
        }

    },
}
</script>

<style scoped>
.play_left {
    width: 100%;
}

.play_top {
    width: 100%;
    height: 280px;
    background: #fff;
    overflow: hidden;
    margin: 0px 0;
    padding: 12px 5px;
    box-sizing: border-box;
    box-shadow: -3px 4px 5px 0px rgb(0 0 0 / 18%);
}

.play_img {
    width: 250px;
    height: 250px;
    background: darkblue;
    border-radius: 50%;
    float: left;
    position: relative;
    border: 1px solid rgba(80, 73, 73, 0.99);
    box-shadow: 1px 4px 8px 4px rgba(0, 0, 0, .1);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    margin-left: 12px;
}

.cl_dh {
    position: absolute;
    top: 0;
    right: -34px;
    z-index: 1;
    width: 91px;
    transform: rotate(-15deg);
    transform-origin: 35px 28px;
    transition: all .3s;
}

.dh {
    transform: rotate(5deg);
}

.play_lan {
    width: calc(100% - 290px);
    height: 250px;
    float: left;
    margin-left: 20px;
    margin-top: 1px;
    position: relative;
}





.slot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.isplay {
    animation: changeright 4s linear infinite;
}

.isstop {
    animation-play-state: paused;
}

@keyframes changeright {
    0% {
        -webkit-transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

.play_start {
    width: 100%;
    height: 100%;
}

.play_end {
    width: 150px;
    height: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    border-radius: 50%;
    text-align: center;
    line-height: 120px;
    overflow: hidden;
    border: 1px solid #fff;
    cursor: pointer;
}

.play_end_image {
    width: 100%;
}

.play_start_img {
    width: 100%;
    height: 100%;
    position: relative;
}

.play_end.bf::before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    opacity: 0;
    background: url(../../../assets/play_logo.png) no-repeat;
    background-size: 100% 100%;
    transition: all 1s ease;
}

.play_end:hover.play_end.no::before {
    opacity: 1;
    transition: all .6s ease;
}

.play_end.no::before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    opacity: 0;
    background: url(../../../assets/no.png) no-repeat;
    background-size: 100% 100%;
    transition: all 1s ease;
}

.play_end:hover.play_end.bf::before {
    opacity: 1;
    transition: all .6s ease;
}

.music_album_title {
    font-size: 28px;
}

.music_album_data {
    margin-top: 10px;
}

.music_album_span {
    height: 100px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.music_album_sc {
    background: #e8e4e4;
    border-color: #000000;
    color: #000;
    border-radius: 20px;
    padding: 6px 20px;
    display: initial;
    cursor: pointer;
}

.music_album_sc.bfs {
    background: #eb454f;
    border-color: #eb454f;
    color: #fff;
}

.music_album_gz {
    background: #e8e4e4;
    border-color: #000000;
    color: #000;
    border-radius: 20px;
    padding: 6px 20px;
    display: initial;
    cursor: pointer;
    margin-left: 30px;
}

.music_album_dz {
    background: #e8e4e4;
    border-color: #000000;
    color: #000;
    border-radius: 20px;
    padding: 6px 20px;
    display: initial;
    cursor: pointer;
    margin-left: 30px;
}

.name_style {
    margin-top: 12px;
    font-size: 16px;
    color: #333;
}

.name_style_nub {
    font-size: 12px;
    color: #999;
}

.play_comment {
    margin-right: 9px;
    position: relative;
    overflow: hidden;
    height: 100px;
    border: 1px solid #ececec;
    background-color: #f5f5f5;
}

.textarea {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 10px;
    box-sizing: border-box;
}

.name_po {
    height: 170px;
    position: relative;
}

.play_btn {
    width: 100px;
    line-height: 27px;
    height: 27px;
    text-align: center;
    border: 1px solid #31c27c;
    background-color: #31c27c;
    color: #fff;
    position: absolute;
    right: 12px;
    margin-top: 12px;
    cursor: pointer;
}

.pod {
    position: absolute;
    width: calc(100% - 10px);
    padding-left: 12px;
    box-sizing: border-box;
}



.play_comment_list {
    margin-top: 12px;
    margin-right: 12px;
    border: 1px solid #ececec;
    background-color: #f5f5f5;
}

.play_comment_son {
    padding: 4px 8px;
    box-sizing: border-box;
    overflow: hidden;
}

.play_comment_name {
    float: left;
}

.play_comment_all {
    float: left;
    margin-left: 8px;
}

/* 3 */
.fiend {
    width: 0px;
    position: fixed;
    bottom: 82px;
    border: 4px;
    left: 250px;
    transition: all 1s ease;
}

.fiend.isplays {
    width: calc(100% - 280px);
    margin: 0 auto;
    height: 20px;
    /* background: #fb7299; */
    border-bottom: 1px solid #ececec;
    box-shadow: 1px 11px 6px rgb(117 117 117);
    border-radius: 12px;
    transition: all 2s ease;

}

.play_bot_all {
    width: 100%;
    height: 100%;
    position: relative
}

.play_bot_play {
    width: 60px;
    height: 60px;
    background: url(../../../assets/download.png) no-repeat;
    background-size: 100% 100%;
    border-radius: 50%;
    z-index: 2;
    box-shadow: 0px 1px 6px #000000;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -30px 0 0 -30px;
    transition: all 3s ease;
}

.play_bot_jdt {
    width: 100%;
    height: 4px;
    background-color: #d6d6d6;
    position: absolute;
    left: 50%;
    bottom: -7px;
    transform: translate(-50%, 0%);
    border-radius: 4px;
    box-shadow: 0px 1px 6px #000000;
    cursor: pointer;
}

.play_bot_img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 3;
    cursor: pointer;
}

.play_bot_img.isplay {
    animation: df 1.2s linear infinite;
}

.play_bot_img.isstop {
    animation-play-state: paused;
}

.ov {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* height: 20px; */
    background: #fb7299;
    box-shadow: 1px 11px 6px rgb(117 117 117);
    border-radius: 12px;
}

.data_time {
    font-size: 10px;
    color: #fff;
    margin-left: 5px;
    float: left;
}

.data_cf {
    float: right;
    font-size: 10px;
    color: #fff;
    margin-right: 5px;
}

.yxdh {
    width: 80px;
    height: 80px;
    background-color: #bdb2b2;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: none;
}

.yxdh.isplay {
    animation: dh 1.2s linear infinite;
    display: block;
}

.yxdh.isstop {
    animation-play-state: paused;
}

@keyframes dh {
    0% {
        transform: scale(0);
        opacity: 0.0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    25% {
        transform: scale(0.05);
        opacity: 0.1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    50% {
        transform: scale(0.1);
        opacity: 0.3;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    75% {
        transform: scale(0.5);
        opacity: 0.5;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    100% {
        transform: scale(1);
        opacity: 0.0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        height: 70px;
    }

}

.comment {
    min-height: 500px;
}

.range {
    background-color: #b5b5b5;
    border-radius: 4px;
    width: 100%;
    -webkit-appearance: none;
    height: 4px;
    cursor: pointer;
    top: -9px;
    position: relative;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: default;
    height: 10px;
    width: 10px;
    transform: translateY(1px);
    background: none repeat scroll 0 0 #fb7299;
    border-radius: 8px;
    -webkit-box-shadow: 0 -1px 1px #444 inset;
    cursor: pointer;
}

.range:focus {
    border: none;
    outline: 0px;
}
</style>
