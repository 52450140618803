/**
 * 引入 axios  拦截器
 */
import apiService from '../utils/service'
import { token } from '../components/js/index'
/**
 *  接口 api
 */
export default {

    songs(data) {
        return apiService({
            url: 'songs/' + data,
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },

        })
    },
    playList(data) {
        return apiService({
            url: '/songs/playList',
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            params:data

        })
    },

    withSongInfo(data) {
        return apiService({
            url: '/user/withSongInfo',
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            params:data

        })
    },

}

